<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      {{ $t('Redirecting') }}...
    </v-layout>
  </v-container>
</template>
<script>
import localStorageService from '@/services/localStorageService'
export default {
  data() {
    return {
      
    }
  },
  created() {
    localStorageService.removeAuthToken()
    setTimeout(() => {
      this.$router.push('/auth/login')
    }, 500)
  }
}
</script>
